
.btn-primario {
    margin: 5px 0 0;
    height: 44px;
    background-color: #4a76b9;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.btn-primario:hover {
    background-color: #5b87ca;
}

.btn-secundario {
    margin: 5px 0 0;
    height: 44px;
    background-color: black;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.btn-secundario:hover {
    background-color: black;
    color: white;
}

.btn-primario2{
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
}

.btn-primario2 .fa-facebook{
    padding-right: 6px;
}
